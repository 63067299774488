<template>
  <div class="ly_news_cb_main">
      <div class="ly_news_cb_title">支原体检测</div>
        <p>在细胞培养过程中，支原体感染发生率达到63%，因而细胞培养过程中被支原体污染是一个世界性的难题。细胞株若受到细菌、真菌、支原体、或是特定病毒等之污染时，会严重的影响实验的结果。而细菌、真菌等之微生物污染时，较易自培养基等的外观变化察觉。但是若受到支原体之污染时，细胞之外观较无明显变化，但是其污染会造成细胞内的DNA、RNA及蛋白表达发生改变，生长速率缓慢、细胞产生病变之型态改变等等变化。 </p>
        <p>因此避免细胞发生支原体污染后仍进行实验极为重要，所以对细胞的支原体检测极为重要。我们采取QPCR法，一步恒温试剂盒，PCR扩增法等多种方法进行支原体检测。可以保证检测的准确性。</p>

        <div class="ly_news_cb_hot_so">送检测样本要求：</div>
        <p>样品：培养两天以上的细胞上清液，体积大于200 μL 请低温运输。</p>

        <p>客户需要提供上清样本，管壁标记清楚细胞的名称，数量较多时要标好标注，并附送检清单。</p>

        <p>每次检测的周期为三个工作日，具体细节请与相关客服人员沟通好。</p>
  </div>
  <footer>
    <ul class="footer-nav">
      <li class="footer-li">
        <a
          href="https://tb.53kf.com/code/client/36b28c56331fefd0e0541943db1facc92/1"
        >
          <img src="/img/icon5-1.59f2eed0.png" alt="" />
          客服
        </a>
      </li>

      <li class="footer-li button download" @click="onBack()" style="background-color: #b9135a">
        <a href="javascript:;"> 返回 </a>
      </li>
    </ul>
  </footer>
</template>

<script lang="ts">
import {
  defineComponent
} from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "StrAppraisal",
  setup() {
    const router = useRouter();
    function onBack() {
      router.back()
    }
    return {
      onBack
    };
  },
});
</script>


<style lang="scss" scoped>
.ly_news_cb_main {
  padding: 15px;
  font-size: 0.42667rem;
  line-height: 0.66667rem;
  color: #666;
  padding-bottom: 1.33333rem;
}

p {
  margin: 1em 0;
  word-break: break-word;
  text-indent: 20px;
}

.ly_news_cb_title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #444;
}

.ly_news_cb_hot_so {
  font-weight: 600;
  color: #444;
  font-size: 18px;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  border-top: 1px solid #f4f4f4;
  font-size: 14px;
  color: #666;
  background-color: #fff;
  z-index: 100;
}
footer a {
  font-size: 14px;
  color: #666;
  display: block;
}

.footer-li {
  float: left;
  width: 17%;
  text-align: center;
  position: relative;
}
.footer-li img {
  display: block;
  width: 19.5px;
  height: 19.5px;
  margin: 5px auto;
}

.footer-li .active-status {
  position: absolute;
  display: none;
  left: 50%;
  margin-left: -9.75px;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.footer-nav .active a {
  color: #b9135a;
}

.footer-nav .active .active-status {
  display: block;
}

footer .button {
  width: 110px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background-color: #e7c527;
  color: #fff;
  border-radius: 36px;
  margin-top: 8px;
  margin-right: 10px;
}
footer .button a {
  color: #fff;
}
.download {
  float: right;
}
</style>